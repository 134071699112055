import React from 'react';
import Layout from '../../layouts/Project';
import ProjectIntro from '../../content/ProjectIntro';
import CapsAndTech from '../../content/CapsAndTech';
import Text from '../../content/Text';
import Caption from '../../content/ImageCaption';
import { StaticImage as Image } from 'gatsby-plugin-image';
import Images from '../../content/Images';
import Video from '../../content/Video';
import Press from '../../content/Press';
import Credits from '../../content/Credits';

export const frontmatter = {
  title: 'Modern Manage\u00ADment Methods',
  projectType: 'Experiences',
  description:
    'Modern Management Methods is an installation that focusses on the United Nations HQ and casts its architecture in an electromagnetic light.',
  release: '2019',
  client: ['Created for Caitlin Blanchfield and Farzin Lotfi-Jam'],
  capabilities: ['Experience Design', 'Software Engineering'],
  technology: ['Processing p5', 'Arduino', 'Circuit Design', 'Fabrication'],
  credits: {
    'Exhibition Curators and Designers':
      'Caitlin Blanchfield and Farzin Lotfi-Jam',
    'Lighting Creative Development': 'Superbright',
    'Lighting Design': 'Igal Nassima',
    'Preservation Consultants': 'Andre Jauregui and Halley Ramos, SOE Studio',
    Radiologist: 'David Yitzhakov'
  },
  order: 5
};

const Page = () => (
  <Layout title={frontmatter.title}>
    <ProjectIntro frontmatter={frontmatter} />
    <Images>
      <Image
        src="../../images/work/modern-management-methods/00.jpg"
        alt="Modern Management Methods"
      />
      <Caption>'Modern Management Methods' at The Shed, NY, 2019</Caption>
    
    <Image
        src="../../images/work/modern-management-methods/Book_MMM_01.jpg"
        alt="Modern Management Methods"
      />
      <Caption>'Modern Management Methods' by Caitlin Blanchfield & Farzin Lotfi-Jam</Caption>
      
      <Image
        src="../../images/work/modern-management-methods/Shed_Online.jpg"
        alt="Modern Management Methods"
      />
      <Caption>'Modern Management Methods' installation at The Shed, NY, 2019</Caption>

      <Image
        src="../../images/work/modern-management-methods/Farzin_Caitlin_02.jpeg"
        alt="Modern Management Methods"
      />
      <Caption>Scanned documents from the UN HQ in New York</Caption>
    </Images>

    <CapsAndTech frontmatter={frontmatter} />
    <Text title="Opportunity" split={1} large>
      <p>
        Writer-editore Caitlin Blanchfield and architect, Farzin Lotfi-Jam
        approached Superbright to help design and build an interactive
        installation (inspired by their 2019 book) that would display the x-ray
        and the archive—paired forms of modernist media— a rendering of the
        United Nations Headquarters, a building built in 1951 by Harrison &
        Abramovitz.
      </p>
      <p>
        Modern Management Methods asks how the value of a building is produced
        through instruments of expertise, management ideologies, and historical
        narratives, by focussing on the HQ since its post 9/11 renovation.
      </p>
      <p>
        The installation was premiered at the inaugural Open Call exhibition at
        The Shed in New York City.
      </p>
    </Text>

    <Video vimeoId="597438385" vimeoCode="53f99c9a52" />

    <Images>
      <Image
        src="../../images/work/modern-management-methods/04.jpg"
        alt="Modern Management Methods"
      />
      <Image
        src="../../images/work/modern-management-methods/02.jpg"
        alt="Modern Management Methods"
      />
      <Image
        src="../../images/work/modern-management-methods/03.jpg"
        alt="Modern Management Methods"
      />
      <Image
        src="../../images/work/modern-management-methods/01.jpg"
        alt="Modern Management Methods"
      />
    </Images>

    <Text title="Solution">
      <p>
        Superbright designed & fabricated an installation where each document is
        displayed on two 25 feet horizontal custom metal enclosures as
        individual lightboxes. A custom control system was built to manage each
        lightbox independently, allowing the grid to be programmed to create
        animation with which to guide visitors through the research by
        highlighting different documents on the panels.
      </p>
    </Text>

    <Images>
      <Image
        src="../../images/work/modern-management-methods/Architects_news.jpg"
        alt="Modern Management Methods"
      />
      <Image
        src="../../images/work/modern-management-methods/Shed_MMM_01.jpg"
        alt="Modern Management Methods"
      />
      <Image
        src="../../images/work/modern-management-methods/Shed_MMM_02.jpg"
        alt="Modern Management Methods"
      />
    </Images>

    <Text title="Output" large>
      <p>
        The final display contained over 100K LEDs, controlled by a custom
        control system housed within a fabricated metal installation, which
        played a light animation sequence across the grid of documents in 15
        minutes cycles. It was installed at The Shed at the inaugural Open Call
        exhibition in 2019.
      </p>
    </Text>

    <Press
      quotes={[
        {
          quote:
            'Modern Management Methods shines a literal light through the U.N. Headquarters',
          heading: 'The Architect’s Newspaper',
          url: 'https://www.archpaper.com/2019/09/modern-management-methods-united-nations-headquarters/',
          date: 'September, 2019'
        }
      ]}
    />
    <Credits credits={frontmatter.credits} />
  </Layout>
);

export default Page;
